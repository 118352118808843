const inMemoryJWTManager = () => {
    let inMemoryJWT: string | null = null;
  
    const getToken = (): string | null => inMemoryJWT;
  
    const setToken = (token: string): boolean => {
      inMemoryJWT = token;
      return true;
    };
  
    const eraseToken = (): boolean => {
      inMemoryJWT = null;
      return true;
    };
  
    return {
      eraseToken,
      getToken,
      setToken,
    };
  };
  
  export default inMemoryJWTManager();
  