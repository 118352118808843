import React from 'react';
import LoginForm from './LoginForm';
import LoginHeader from './LoginHeader';
import LoginReset from './LoginReset';
import { Footer, Layout, Content } from '@servahealth/serva-ui-kit';
import { useSearchParams } from 'react-router-dom';
import packageJson from '../../../package.json';
import { ConfigProvider } from 'antd';
import useAuth from '../../Hooks/useAuth';

const Login = () => {
	const [params] = useSearchParams();
	const { clientId } = useAuth();
	const { reset } = Object.fromEntries(params);

	return (
		<ConfigProvider 
			theme={{
				token: {
					colorPrimary: '#009ee2'
				}
		  	}}
		>
			<Layout>
				<LoginHeader key={clientId} clientId={clientId ?? ''} />
				<Content>
					{reset ? <LoginReset resetToken={reset} /> : <LoginForm />}
				</Content>
				<Footer version={packageJson.version} />
			</Layout>
		</ConfigProvider>
	);
};

export default Login;
