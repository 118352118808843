import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { UserOutlined, LockOutlined, DownCircleOutlined } from '@ant-design/icons';
import { useMutation } from 'react-query';
import TwoFactorModal from './TwoFactorModal';
import useAuth from '../../Hooks/useAuth';
import { Tag, Input } from 'antd';
import { 
    Loading,
    Col,
    Row,
    Button,    
    Card,
    Space,
    Divider,
    ServaAlert as Alert,
    Checkbox,
    ServaModal as Modal,
    Form,
    Title,
    openNotification
} from '@servahealth/serva-ui-kit';
import blank from '../../images/blank.png';

const LoginForm = () => {
    const navigate = useNavigate();
    const [params] = useSearchParams();
    
    const location = useLocation();

    const {
        login,
        setIsAuthenticated,
        isAuthenticated,
        refreshToken,
        refreshAuthFromToken,
        hash,
        setHash,
        setRedirect,
        redirect,
        setClientId
    } = useAuth();

    setClientId(params.get('client_id')); // the underlying logo file stored on the server is based on the client_id

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [step, setStep] = useState(1);
    const [remember, setRemember] = useState(false);
    const [showTwoFactorModal, setShowTwoFactorModal] = useState(false);
    const [authType, setAuthType] = useState('');
    const [splash, setSplash] = useState(true);

    const effectRan = useRef(false);
    useEffect(() => {
        if (!effectRan.current) {
            const checkApiHealth = async () => {
                try {
                    await fetch(`${process.env.REACT_APP_API_ROOT}/V1/Utilities/health`);
                } catch (error) {
                    if (error.message === 'Network Error' || error?.toString() === 'TypeError: NetworkError when attempting to fetch resource.') {
                        Modal.info({
                            title: 'System Maintenance',
                            content: (
                                <div>
                                    System is currently down for maintenance.
                                    <br />
                                    Please reload the page in a few moments and try
                                    again.
                                    <br />
                                    Thank you for your patience.
                                </div>
                            ),
                            okText: 'Reload',
                            onOk: () => window.location.reload(),
                        });
                    }
                }
            };

            if (!!params.get('code') && !!redirect) {
                // refresh auth might take a few seconds, so show the splash for a bit
                setTimeout(() => {
                    setSplash(false);
                }, 4500);

                authFromSSOToken?.({ 
                    accessToken: params.get('code'), 
                    callback: checkForUpdates,
                    provider: redirect.provider,
                    code_challenge: redirect.code_challenge,
                    code_challenge_method: redirect.code_challenge_method,
                    redirect_uri: window.location.origin + location.pathname
                });

               
            } else if (refreshToken) {
                refreshAuthFromToken?.({ callback: checkForUpdates });

                // refresh auth might take a few seconds, so show the splash for a bit
                setTimeout(() => {
                    setSplash(false);
                }, 2500);
            } else {
                // no refresh auth, so get rid of splash
                setSplash(false);
            }

            checkApiHealth();
        }

        if (window.location.pathname.includes('loginredirect'))
            validateRequest();

        return () => effectRan.current = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { mutate: validateRequest, isError: isValidateError, error: validateError } = useMutation(
        () => {
            return axios.get(
                `${process.env.REACT_APP_API_ROOT}/V1/Auth/validateRequest`,
                { params }
            );
        }
    );

    useEffect(() => {
        setIsAuthenticated(isAuthenticated);
    }, [isAuthenticated, setIsAuthenticated]);

    const { mutate: checkSsoProvider, isLoading: isSsoProviderLoading, isError: isSsoProviderError, error: sSoProviderError } = useMutation(
        ({ emailAddress }) => {
            return axios.get(
                `${process.env.REACT_APP_API_ROOT}/V1/Auth/ssoProviders`, { params: { emailAddress } }
            );
        },
        { 
            onSuccess: (resp) => {
                const providers = resp?.data;
                if (providers.length === 0) {
                    setStep(2);
                } else if (providers[0].protocol === 'oAuth2') {
                    const secret = crypto.randomUUID();
                    setHash(secret);
                    const utf8 = new TextEncoder().encode(secret);
                    crypto.subtle.digest('SHA-256', utf8).then((hashBuffer) => {
                        const hashArray = Array.from(new Uint8Array(hashBuffer));
                        const hashBase64 = btoa(String.fromCharCode.apply(null, hashArray)).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');

                        // purge any expired auth_tokens from redirect URL
                        let redirectLocation = `${window.location}`;
                        if (redirectLocation.includes('code'))
                            redirectLocation = redirectLocation.replace('?code=', '').replace('&code=', '').replace(params.get('code'), '');
                      
                        setRedirect(JSON.stringify({
                            code_challenge: params.get('code_challenge'),
                            code_challenge_method: params.get('code_challenge_method'),
                            response_type: params.get('response_type'),
                            redirect_uri: params.get('redirect_uri'),
                            provider: providers[0].id
                        }));

                        window.location = providers[0].redirectUrl + `&code_challenge=${hashBase64}&code_challenge_method=S256&login_hint=${email}&redirect_uri=${encodeURIComponent(window.location.origin + location.pathname)}`;                    
                    }); 

                }
            },
        }
    );

    const checkEmail = () => {
        if (!!email && email !== '')
            checkSsoProvider({ emailAddress: email });
    }

    // checks if versions match, then continues login process
    const checkForUpdates = (res) => {
        if (res?.data) {

            // if user has older version, show modal for them to reload app
            //if (lt(version, user.currentVersion)) {
            if (false) {
                Modal.info({
                    title: 'New Version Available',
                    content: (
                        <div>
                            We have some changes for you!
                            <br />
                            Reload the page to get the updated version
                        </div>
                    ),
                    okText: 'Reload',
                    onOk: () => window.location.reload(),
                });
            } else {
                checkForMFA(res?.data);
            }
        }
    };

    // checks if user needs 2FA, else continues log in process
    const checkForMFA = (user) => {
        if (user.twoFactorRequired) {
            if (user.twoFactorViaEmail) {
                setAuthType('email');
            } else {
                setAuthType('phone');
            }
            setShowTwoFactorModal(true);
        } else {
            finishLogin(user);
        }
    };

    // final step in login process, then pushes to root authenticated path
    const onLoginSuccess = (user) => {
        try {
            if (remember) {
                localStorage.remember = true;
                localStorage.email = email ?? user.email;
            } else {
                localStorage.removeItem('email');
                localStorage.remember = false;
            }
        } catch (err) {}

        if (window.location.pathname.includes('loginredirect')) {
            const redir = !!redirect?.redirect_uri ? redirect?.redirect_uri : params.get('redirect_uri');
            window.location = redir + (redir.includes('?') ? '&' : '?') + 'code=' + user?.token;
        } else {
            login(user);
            navigate('/home');
        }
    };

    const { mutate, isLoading, isError, error } = useMutation(
        () => {
            return axios.post(
                `${process.env.REACT_APP_API_ROOT}/V1/Auth/authRedirect`,
                {
                    email: email,
                    password: password,
                    code_challenge: params.get('code_challenge'),
                    code_challenge_method: params.get('code_challenge_method'),
                    client_id: params.get('client_id'),
                }
            );
        },
        { onSuccess: checkForUpdates }
    );

    const { mutate: authFromSSOToken } = useMutation(
        ({ accessToken, provider, code_challenge, code_challenge_method, redirect_uri }) => {
            return axios.post(
                `${process.env.REACT_APP_API_ROOT}/V1/Auth/sso`, { 
                    accessToken, 
                    provider, 
                    code_verifier: hash,
                    code_challenge,
                    code_challenge_method,
                    redirect_uri
                }
            );
        },
        { onSuccess: checkForUpdates, onError: () => openNotification('error', 'There was a problem trying to login. Please reload the page and try again.') }

    );

    const handleSubmit = () => {
        mutate();
    };
    
    // checks and shows system alerts, then continues log in process
    const finishLogin = ({ systemAlerts, ...user }) => {
        if (systemAlerts?.length > 0) {
            const alertItems = systemAlerts.map(({ id, created, content }) => (
                <div key={id}>
                    <Row>
                        <h6>{new Date(created).toLocaleString()}</h6>
                    </Row>
                    <Row>
                        <p>{content}</p>
                    </Row>
                </div>
            ));
            Modal.info({
                title: <h4>System Alert{systemAlerts.length > 1 && 's'}</h4>,
                width: 600,
                content: (
                    <Space
                        direction="vertical"
                        split={<Divider className="my-1" />}
                        style={{
                            maxHeight: '600px',
                            overflowY: 'auto',
                            width: '100%',
                        }}
                    >
                        {alertItems}
                    </Space>
                ),
                onOk: () => onLoginSuccess(user),
            });
        } else {
            onLoginSuccess(user);
        }
    };


    const handleEmailChange = (event) => setEmail(event.target.value);
    const handlePasswordChange = (event) => setPassword(event.target.value);
    const handleRememberChange = (event) => setRemember(event.target.checked);

    //check local storage for remember me
    useEffect(() => {
        try {
            if (localStorage.remember === 'true') {
                setEmail(localStorage.email);
                setRemember(true);
            }
        } catch (err) {}
    }, []);

    const formFields = [
        {
            name: 'email',
            value: email,
        },
        {
            name: 'remember',
            value: remember,
        },
    ];

    const emailRef = useRef();
    const passRef = useRef();

    useLayoutEffect (() => {
        if (step === 2 && passRef.current) {
            setTimeout(() => {
                passRef.current.focus();
            }, 300);
        } else if (emailRef.current)
            emailRef.current.focus();
    }, [step])

    if (splash) return <Loading logoSrc={blank} />;

    return (
        <Row type="flex" justify="center" align="middle">
            <Col xs={22} sm={15} md={9} xl={4} xxl={4}>
                <Card className="text-center" bodyStyle={{ paddingBottom: 10 }}>
                    {isError ? (
                        <Alert
                            message={
                                error.response?.data.message ??
                                'Something went wrong. Please refresh and try again in several minutes.'
                            }
                            type="error"
                            showIcon
                            className="mb-2"
                        />
                    ) : null}
                    {isValidateError ? <Alert type='error' message='Invalid Request' description={`Missing: ${validateError?.response?.data?.message}`} /> : undefined}
                    {isSsoProviderError ? <Alert type='error' message='Invalid Email' description={sSoProviderError?.response?.data?.message} /> : undefined}
                    <Form
                        name="login"
                        className="justify-content-center"
                        initialValues={{
                            remember,
                            email,
                        }}
                        fields={formFields}
                        onFinish={handleSubmit}
                        onSubmit={() => {}}
                    >
                        <div className={`left-to-right-container step${step}`}>
                            <div className={`notification-container ${step === 2 ? 'dismiss-left' : ''}`}>
                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your Email',
                                        },
                                    ]}
                                >
                                    <Input
                                        prefix={
                                            <UserOutlined className="site-form-item-icon" />
                                        }
                                        placeholder="Email"
                                        value={email}
                                        className="mt-2"
                                        onChange={handleEmailChange}
                                        onKeyDown={(e) => { 
                                            if (e.keyCode === 13 && !isValidateError) {
                                                e.preventDefault(); 
                                                checkEmail(); 
                                            } 
                                        }}
                                        ref={emailRef}
                                        autoFocus
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        onClick={checkEmail}
                                        block
                                        disabled={isLoading || isValidateError}
                                        loading={isSsoProviderLoading}
                                    >
                                        Continue
                                    </Button>
                                </Form.Item>
                            </div>
                            <div className={`notification-container ${step === 2 ? 'enter-right' : 'hidden-step'}`}>
                                <Space size={0} className="w-100 mb-4" direction="vertical">
                                    <Title className="m-0 p-0" level={5}>Welcome </Title>
                                    <span 
                                        style={{ cursor: 'pointer' }} 
                                        className="m-0 p-0" 
                                        onClick={() => {
                                            setEmail(null);
                                            setStep(1);
                                        }}
                                    >
                                        <Tag 
                                            bordered={false}
                                        >
                                            <DownCircleOutlined />{' '}{email}
                                        </Tag>
                                    </span>
                                </Space>
                                <Form.Item
                                    className="mb-0"
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Password!',
                                        },
                                    ]}
                                >
                                    <Input.Password 
                                        prefix={
                                            <LockOutlined className="site-form-item-icon" />
                                        }
                                        className="mt-2"
                                        placeholder="Password"
                                        value={password}
                                        ref={passRef}
                                        onChange={handlePasswordChange}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        block
                                        className="mt-2"
                                        disabled={isLoading}
                                        loading={isLoading}
                                    >
                                        Log In
                                    </Button>
                                </Form.Item>
                            </div>
                        </div>
                        <Form.Item
                            className="my-2"
                            name="remember"
                            valuePropName="checked"
                        >
                            <Checkbox
                                value={remember}
                                onChange={handleRememberChange}
                            >
                                Remember me
                            </Checkbox>
                        </Form.Item>
                        <Form.Item>
                            <Link target='_blank' to="/password_reset">Forgot password</Link>
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
            <TwoFactorModal
                visible={showTwoFactorModal}
                onCancel={() => setShowTwoFactorModal(false)}
                email={email}
                onSuccess={finishLogin}
                authType={authType}
                codeChallenge={params.get('code_challenge')}
                codeChallengeMethod={params.get('code_challenge_method')}
                clientId={params.get('client_id')}
            />
        </Row>
    );
};

export default LoginForm;
