import axios, { AxiosError, AxiosResponse } from 'axios';
import React, { createContext, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useMutation, useQueryClient } from 'react-query';
import inMemoryJWT from './inMemoryJwt';
import AuthResponseModel from '../Interfaces/AuthResponseModel';
import CurrentUserModel from '../Interfaces/CurrentUserModel';
import AuthContextModel from '../Interfaces/AuthContextModel';
import GenericStringObject from '../Interfaces/GenericStringObject';
import { openNotification } from '@servahealth/serva-ui-kit';

const localStorageKeys = ['remember', 'username', 'email'];

interface AuthVariablesType {
    callback?: (...rest: unknown[]) => void;
}

interface Props {
    children: JSX.Element;
}

export const AuthContext = createContext<AuthContextModel>({
    isAuthenticated: false,
    setIsAuthenticated: function (): void {},
    login: function (): void {},
    logout: function (): void {},
    setUser: function (): void {},
    refreshToken: '',
    refreshAuthFromToken: function (): void {},
    hash: undefined,
    setHash: function (): void {},
    redirect: undefined,
    setRedirect: function (): void {},
    clientId: undefined,
    setClientId: function(): void {}
});

const AuthenticationContextProvider = (props: Props) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState<CurrentUserModel>();
    const [cookies, setCookie, removeCookie] = useCookies(['refreshToken', 'sHSSOEXTChallenge', 'sHSSORedirect']);
    const [clientId, setClientId] = useState<string | undefined>();

    const queryClient = useQueryClient();

    const { mutate } = useMutation(
        () => {
            return axios.post(
                `${process.env.REACT_APP_API_ROOT}/V1/Authenticate/${cookies['refreshToken']}`
            );
        },
        {
            onSuccess: (data) => {
                const user = data.data.value;
                inMemoryJWT.setToken(user.token);
                setRefreshTokenCookie(user.refreshToken);
            },
            onSettled: (data, error, variables: AuthVariablesType | void) => {
                if (!error && variables && variables.callback) {
                    variables.callback(data);
                }
            },
        }
    );

    const setUserInfo = (userInfo: AuthResponseModel) => {
        const newUser = {
            id: userInfo.id,
            twoFactorRequired: userInfo.twoFactorRequired,
            twoFactorViaEmail: userInfo.twoFactorViaEmail,
            email: userInfo.email,
            token: userInfo.token,
            refreshToken: userInfo.refreshToken,
            expires: userInfo.expires,
        };

        setUser(newUser);
    };

    const login = (user: AuthResponseModel) => {
        resetLocalStorage();
        setIsAuthenticated(true);
        const { token, refreshToken } = user;
        inMemoryJWT.setToken(token);

        // set cookie and start countdown to silent refresh
        setRefreshTokenCookie(refreshToken);

        // set user info (brands, tenants, access, metadata)
        setUserInfo(user);
    };

    const logout = (reason = '') => {
        removeCookie('refreshToken', {
            path: '/',
            domain: window.location.hostname,
        });
        setIsAuthenticated(false);
        setUser(undefined);
        resetLocalStorage();
        inMemoryJWT.eraseToken();
        queryClient.removeQueries();

        if (reason !== '') {
            openNotification(
                'warning',
                'You have been logged out. Reason:' + reason,
                30,
                undefined,
                'logout_notification',
                undefined
            );
        }
    };

    const resetLocalStorage = () => {
        const backup: GenericStringObject = {};
        localStorageKeys.forEach((k) => {
            const value = localStorage.getItem(k);
            if (value) backup[k] = value;
        });

        localStorage.clear();

        localStorageKeys.forEach((k) => {
            if (backup[k]) localStorage.setItem(k, backup[k]);
        });
    };

    const getNewRefreshToken = () => {
        mutate();
    };

    const setRefreshTokenCookie = (refreshToken: string) => {
        const currDate = new Date();
        const expires = new Date();
        expires.setHours(new Date().getHours() + 8);

        const expireTimeMS = expires.getTime() - currDate.getTime();

        setCookie('refreshToken', refreshToken, {
            path: '/',
            expires: expires,
            secure: true,
            domain: window.location.hostname,
        });

        if (inMemoryJWT.getToken()) {
            setTimeout(() => {
                getNewRefreshToken();
            }, expireTimeMS);
        }
    };

    const handleHash = (value: string | undefined) => {
        const expires = new Date();
        expires.setMinutes(new Date().getMinutes() + 30);
        setCookie('sHSSOEXTChallenge', value, {
            path: '/',
            expires,
            secure: true,
            domain: window.location.hostname,
            // httpOnly: true
        });
    };

    const handleRedirect = (value: string | undefined) => {
        const expires = new Date();
        expires.setMinutes(new Date().getMinutes() + 30);
        setCookie('sHSSORedirect', value, {
            path: '/',
            expires,
            secure: true,
            domain: window.location.hostname,
            // httpOnly: true
        });
        
    }

    axios.interceptors.request.use(
        function (config: any) {
            const { origin } = new URL(config.url ?? '');
            const allowedOrigins = process.env.REACT_APP_API_ROOT;
            const token = inMemoryJWT.getToken();

            if (allowedOrigins?.includes(origin)) {
                const _headers = config.headers ?? {};
                config.headers = {
                    ..._headers,
                    authorization: `Bearer ${token}`,
                };
            }
            if (config.method === 'delete' && !config.data) {
                config.data = { delete: true };
            } else if (config.method === 'post' && !config.data) {
                config.data = {};
            }

            return config;
        },
        function (error) {
            return Promise.reject(error);
        }
    );

    axios.interceptors.response.use(
        (response: AxiosResponse<any, any>) => {
            return response;
        },
        (err: AxiosError) => {
            return new Promise((resolve, reject) => {
                if (err?.response?.status === 401) {
                    if (isAuthenticated) logout('Unauthorized');
                }
                return reject(err);
            });
        }
    );

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated: isAuthenticated,
                setIsAuthenticated: setIsAuthenticated,
                login,
                logout,
                user,
                setUser: setUserInfo,
                refreshToken: cookies['refreshToken'],
                refreshAuthFromToken: mutate,
                setHash: handleHash,
                hash: cookies['sHSSOEXTChallenge'],
                setRedirect: handleRedirect,
                redirect: cookies['sHSSORedirect'],
                clientId,
                setClientId
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthenticationContextProvider;
